import { AuthService } from './auth.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { NpxMessagesComponent } from '../components/npx-dialogs/npx-messages/npx-messages.component';
import { Injectable } from '@angular/core';
import { functions, storage } from '../../environments/environment';
import { httpsCallable } from 'firebase/functions';
import { getDownloadURL, ref } from 'firebase/storage';

@Injectable()
export class StorageModal {

    constructor(private userService: AuthService, public dialog: MatDialog) {
    }

    /**
     * Retrieve list of events from eventEntries having participantId=loggedInUserID, and those events must contain any file or folder in storage
     */
    getStorageEvents() {

        return httpsCallable(functions, 'getUserEventList')({
            getInfo: 'eventList'
        }).then(eventList => {
            return Promise.resolve(eventList);
        }).catch((err) => {
            console.error('getStorageEvents: Error occurred while calling backend function to get list of events associated with login user', err);
            return this.handleError(err);
        });
    }

    getAllFiles(path: string) {
        return httpsCallable(functions, 'getStorageFilesFoldersNames')(path).then((list) => {
            return Promise.resolve(list);
        }).catch((err) => {
            return this.handleError(err);
        });
    }

    getDownloadUrl(path) {
        // Get a reference to the storage service, which is used to create references in your storage bucket
        // const storage = firebase.storage();

        // Create a storage reference from our storage service
        const storageRef = ref(storage, path);
        return getDownloadURL(storageRef).then((url) => {
            return Promise.resolve(url);
        }).catch((err) => {
            console.error(`getDownloadUrl: Error occurred while fetching download URL ${path}`, err);
            return Promise.reject('Error occurred while fetching download URL');
        });
    }

    handleError(err) {
        if (err.code === 'permission-denied') {
            return this.dialog.open(NpxMessagesComponent, {
                width: '300px',
                data: { header: err.code, body: err }
            }).afterClosed().toPromise().then(() => {
                return Promise.reject(err);
            }).catch(() => {
                return Promise.reject(err);
            });
        } else {
            return Promise.reject(err);
        }
    }

    /**
     * Call backend function here in order to retrieve cached URL path
     * @param path - Path of file
     */
    retrieveCachedURL(path) {
        return new Promise((resolve, reject) => {
            const loggedInUserId = this.userService.userClaims.getValue()['user_id'];
            return httpsCallable(functions, 'userLibraryCache')({
                userId: loggedInUserId,
                filePath: path
            }).then((data: any) => {
                if (data.data.success) {
                    const cachedUrl = data.data.data.msg;
                    // console.log(cachedUrl);
                    return this.getDownloadUrl(cachedUrl).then((url) => {
                        resolve({ err: false, url: url, cacheFileName: cachedUrl });
                    }).catch(err => {
                        resolve({ err: true, msg: err });
                    });
                } else {
                    resolve({ err: true, msg: data.data.data.msg });
                }
            }).catch((err) => {
                console.error('Error response from backend function is : ', err);
                resolve({ err: true, msg: 'Error occurred while fetching image' });
            });
        });
    }

}
