import { Component, Inject, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

@Component({
    selector: 'inpraxi-npx-npx-messages',
    templateUrl: './npx-messages.component.html',
    styleUrls: ['./npx-messages.component.scss']
})

export class NpxMessagesComponent implements OnInit {

    constructor(
        public dialogRef: MatDialogRef<NpxMessagesComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData) {
    }

    onClose() {
        this.dialogRef.close();
    }

    ngOnInit() {
    }
}

export interface DialogData {
    header: string;
    body: string;
}
