import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import {BehaviorSubject, Observable} from 'rxjs';
import {AuthService} from '../services/auth.service';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard  {

    userClaims = new BehaviorSubject({});

    constructor(private authService: AuthService, private router: Router) {
    }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

        if (!next.data.roles) {
            console.log('canActivate: no restrictions on route', next);
            return true;
        }

        return this.authService.isLogin().then(() => {
            return this.authService.verifyRole(next.data.roles).then().catch(() => {

                /* As role doesn't match, navigate user to that screen that is allowed to view for all users i.e. dashboard */
                this.router.navigate(['/dashboard']).then().catch((err) => {
                    console.log('Error occurred while navigating to dashboard', err);
                });
            });
        }).catch(() => {
            
            /* User not logged in, navigate it to login screen */
            console.log('User is not logged in, navigating to login screen');
            this.router.navigate(['/auth/login'], {queryParams: {returnUrl: state.url}}).then().catch((err) => {
                console.log('Error occurred while navigating to login page', err);
            });
        });
    }
}
