import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {  MatButtonModule } from '@angular/material/button';
import {  MatCardModule } from '@angular/material/card';
import {  MatCheckboxModule } from '@angular/material/checkbox';
import {  MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {  MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import {  MatMenuModule } from '@angular/material/menu';
import {  MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import {MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import {  MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import {  MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';

import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { MarkdownModule } from 'ngx-markdown';
import { MatRadioModule } from '@angular/material/radio';
import { MatProgressBarModule } from '@angular/material/progress-bar';

@NgModule({
    imports: [
        CommonModule,
        MatToolbarModule,
        FlexLayoutModule,
        MatButtonModule,
        MatCardModule,
        MatInputModule,
        MatDialogModule,
        MatTableModule,
        MatMenuModule,
        MatIconModule,
        MatProgressSpinnerModule,
        MatStepperModule,
        MatSelectModule,
        MatCheckboxModule,
        MatGridListModule,
        MatSnackBarModule,
        MatDividerModule,
        MatListModule,
        MatSidenavModule,
        FormsModule,
        MatSortModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MarkdownModule.forRoot(),
        MatRadioModule,
        MatProgressBarModule
    ],

    exports: [
        CommonModule,
        MatToolbarModule,
        MatButtonModule,
        MatCardModule,
        FlexLayoutModule,
        MatInputModule,
        MatDialogModule,
        MatTableModule,
        MatMenuModule,
        MatIconModule,
        MatProgressSpinnerModule,
        MatStepperModule,
        MatSelectModule,
        MatCheckboxModule,
        MatGridListModule,
        MatSnackBarModule,
        MatDividerModule,
        MatListModule,
        MatSidenavModule,
        MatSortModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatChipsModule,
        MatRadioModule
    ],

    providers: []
})
export class MaterialModule {
}


