import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { TestChunkLoadErrorComponent } from './test-chunk-load-error/test-chunk-load-error.component';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./components/components.module').then(m => m.ComponentsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: 'error',
    component: TestChunkLoadErrorComponent
  },
  {
    path: '**',
    loadChildren: () => import('./status-pages/status-pages.module').then(m => m.StatusPagesModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class AppRoutingModule {
}
