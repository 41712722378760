export function ContactMailingGroupCollectionPath() {
    return 'Navision/data/ContactMailingGroup';
}

export function ContactMailingGroupDocPath(contactID: string) {
    return 'Navision/data/ContactMailingGroup/' + contactID;
}

export function EventDataCollectionPath() {
    return 'Navision/data/Event/';
}

export function EventDataDocPath(eventItemNo: string) {
    return 'Navision/data/Event/' + eventItemNo;
}

export function EventLecturerDataCollectionPath() {
    return `Navision/data/EventLecturer/`;
}

export function EventLecturerDataDocPath(eventLecturerID: string) {
    return `Navision/data/EventLecturer/` + eventLecturerID;
}


export function EventSettingsCollectionPath() {
    return 'npx/data/eventSettings';
}

export function EventSettingsDocPath(eventNo: string) {
    return 'npx/data/eventSettings/' + eventNo;
}

export function EventHotelCostCollectionPath() {
    return 'Navision/data/EventHotels/';
}

export function EventHotelCostDocPath(eventNo: string) {
    return 'Navision/data/EventHotels/' + eventNo;
}

export function ContactCollectionPath() {
    return 'Navision/data/Contact';
}

export function ContactDocPath(contactNo: string) {
    return 'Navision/data/Contact/' + contactNo;
}

export function TemplateCollectionPath() {
    return 'Navision/data/Event/';
}

export function TemplateComponentCollectionPath(template: string) {
    return `templates/${template}/component/`;
}

export function TemplateComponentDocPath(template: string, component: string) {
    return `templates/${template}/component/` + component;
}

export function TemplateComponentBackupCollectionPath(template: string, component: string) {
    return `templates/${template}/component/${component}/backup`;
}

export function TemplateComponentBackupDocPath(template: string, component: string, backupID: string) {
    return `templates/${template}/component/${component}/backup/${backupID}`;
}

export function EventServiceCollectionPath() {
    return 'Navision/data/EventService/';
}

export function EventServiceDocPath(serviceId: string) {
    return `Navision/data/EventService/${serviceId}`;
}

export function HotelReservationCollectionPath() {
    return 'Navision/data/HotelReservation/';
}

export function HotelReservationDocPath(eventNo: string) {
    return `Navision/data/HotelReservation/${eventNo}`;
}

export function BannerCollectionPath() {
    return 'settings/banner/';
}

export function EventParticipantCollectionPath(userId: string, eventNo: string) {
    return `users/${userId}/bookings/${eventNo}/participants/`;
}

export function EventParticipantDocPath(userId: string, eventNo: string, bookingId: string) {
    return `users/${userId}/bookings/${eventNo}/participants/${bookingId}`;
}

export function EventBookingCollectionPath(userId: string) {
    return `users/${userId}/bookings`;
}

export function EventBookingDocPath(userId: string, eventNo: string) {
    return `users/${userId}/bookings/` + eventNo;
}


export function EventDeclinedEventsCollectionPath(userId: string) {
    return `users/${userId}/declinedEvents/`;
}

export function EventDeclinedEventsDocPath(userId: string, eventNo: string) {
    return `users/${userId}/declinedEvents/` + eventNo;
}

export function RegistrationRequestCollectionPath() {
    return 'npx/data/registrationRequest/';
}

export function RegistrationRequestDocPath(docID: string) {
    return 'npx/data/registrationRequest/' + docID;
}

export function UsersCollectionPath() {
    return 'users/';
}

export function UsersDocPath(userID: string) {
    return 'users/' + userID;
}

export function UsersLoginCollectionPath() {
    return 'npx/data/userLogin/';
}

export function UsersLoginDocPath(userID: string) {
    return 'npx/data/userLogin/' + userID;
}

export function EventEntriesCollectionPath() {
    return `Navision/data/EventEntries/`;
}

export function EventEntriesDocPath(docID: string) {
    return `Navision/data/EventEntries/${docID}`;
}

export function NavRegistrationDocPath(eventID: string) {
    return `Navision/data/Registration/${eventID}`;
}

export function NavRegistrationCollectionPath() {
    return `Navision/data/Registration/`;
}

export function userCacheCollectionPath() {
    return 'userCache/';
}

export function downloadProgressCollectionPath() {
    return 'downloadProgress/';
}

export function MailsCollectionPath() {
    return 'mails/';
}

export function MailsFailedCollectionPath() {
    return 'mailsFailed/';
}

export function ActivityLogCollectionPath() {
    return 'ActivityLog/';
}

export function EventIndexesCollectionPath() {
    return 'indexes/';
}

export function EventEntries2yCollectionPath() {
    return `Navision/data/EventEntries2y/`;
}

export function EventEntries2yDocPath(docID: string) {
    return `Navision/data/EventEntries2y/${docID}`;
}

export interface UserDoc {
    role: string,
    erfaGroups: Array<string>
}

export interface UserObject {
    uid: string,
    firstName: string,
    lastName: string,
    fullName: string,
    role: string,
    email: string,
    password: string,
    emailVerification: boolean,
    isDisabled: boolean,
    company: string,
    pendingBookings: boolean,
    webAccess?: boolean
}

export interface EmailObject {
    emailPurpose: string;
    emailSubject: string;
    emailBody: string;
    emailSignature: string;
    emailSalutationCode: string;
    eventDesc1: string;
    eventDesc2: string;
    userCompanies: string,
    userFirstName: string;
    userLastName: string;
    userEmail: string;
    userPhoneNo: string;
    recipientEmail: string;
    calenderEvent?: string;
    recipientEmails?: string[];
}

export interface RegistrationObject {
    regUserCompany: string;
    regUserEmail: string;
    regUserFirstName: string;
    regUserLastName: string;
    regUserPhoneNo: string;
}

export interface NavRegistration {
    Address_2_Company: string;
    Address_2_Participant: string;
    Address_Company: string;
    Address_Participant: string;
    Bank_Name: string;
    Cancelation_Participant: boolean;
    City_Company: string;
    City_Participant: string;
    Contact_No_Applicant: string;
    Contact_No_Company: string;
    Contact_No_Participant: string;
    Course_No: string;
    Customer_Price_Group: string;
    E_Mail_Company: string;
    E_Mail_Participant: string;
    Entry_No: number;
    Event_No: string;
    First_Name: string;
    IBAN: string;
    IP_Address: string;
    Job_Title: string;
    Middle_Name: string;
    Mobile_Phone_No_Participant: string;
    Name_2_Company: string;
    Name_Company: string;
    Owner: string;
    Payment_Method: string;
    Phone_No_Company: string;
    Phone_No_Participant: string;
    Phone_No_private_Particip: string;
    Post_Code_Company: string;
    Post_Code_Participant: string;
    Remark: string;
    SWIFT_Code: string;
    Salutation: string;
    Surname: string;
    Title_behind_surname: string;
    Title_pre_surname: string;
    Transaction_ID_Payment: string;
    md5: string;
    source: string;
}

export interface EventObject {
    No: string,
    Course_No: string,
    Template_No: string,
    Kind: string,
    Start_date: string,
    End_date: string,
    TimeFrom?: string,
    TimeTo?: string,
    Description: string,
    Description_2: string,
    Target_Group?: string,
    Target_Group_Description?: string,
    Place_of_Event: string,
    Place_of_Event_Name: string,
    Duration: number,
    Min_Participant: number,
    Max_Participant: number,
    On_Waiting_List?: number,
    Participant_total?: number,
    No_of_Course_Leader: number,
    Quantity_Hotels: number,
    Quantity_Add_Events: number,
    Status: string,
    License_Class?: string,
    Level?: number,
    Department_Section: string,
    Catering_Accommodation?: string,
    Internet_release?: boolean,
    Salesperson_Code: string,
    Contact_Person: string,
    Early_Bird_Discount_Date?: string,
    No_of_Dates: number,
    Anmeldeschluss: string,
    Name_des_Veranstaltungsortes: string,
    Stra\u00dfe_des_Veranstaltungsortes?: string,
    PLZ_des_Veranstaltungsortes?: string,
    Ort_des_Veranstlatungsortes?: string,
    Ansprechpartner_Name: string,
    Ansprechpartner_E_Mail: string,
    Ansprechpartner_Telefon: string,
    Gastgebercode: string,
    Gastgeber: string,
    Gastgeberlink: string,
    Verteilercode: string,
    Company: string,
    paticipant?: string,
    applicant?: string,
    hasBooking?: boolean,
    noErfaBooking?:boolean,
    noNavisionEntry?: boolean
}

export interface EventLecturersObject {
    Event_No: string;
    Course_Leader_No: string;
    Name: string;
    Name_2: string;
    Remark: string;
    Direction: boolean;
    Company: string;
}

export interface EventBooking {
    acceptBooking: boolean;
    finalizeBooking: boolean;
    reminderMailSent: boolean;
}

export interface EventBooking {
    acceptBooking: boolean;
    finalizeBooking: boolean;
    reminderMailSent: boolean;
}

export interface EventUserParticipation {
    Booking_Cancelled: boolean;
    Booking_Cancelled_Comment: string;
    Checkin_Date: string;
    Checkout_Date: string;
    Event_Booking: boolean;
    First_Name: string;
    Hotel_Booking: boolean;
    Hotel_Id: string;
    Last_Name: string;
    Login_User: boolean;
    Personal_Note: string;
    RoomType_Desc: string;
    Room_Type: string;
    Saved_To_Navision: boolean;
    Event_No: string;
    User_Id: string;
    Finalize_Booking: boolean;
}

export interface ContactObject {
    No: string,
    Name: string,
    Name_2: string,
    Company_No: string,
    Address: string,
    Address_2?: string,
    City: string,
    Phone_No?: string,
    Fax_No?: string,
    Post_Code?: string,
    Country_Region_Code?: string,
    E_Mail: string,
    Home_Page?: string,
    Type: string,
    First_Name: string,
    Middle_Name: string,
    Surname: string,
    Job_Title: string,
    Mobile_Phone_No?: string,
    Organizational_Level_Code: string,
    Salutation_Code: string,
    Place_of_Event: boolean,
    Department?: string,
    Title_pre_surname?: string,
    Title_behind_surname?: string,
    Birth_Date: string,
    Sex: string,
    Birth_Date_Month_Day: string,
    Course_Leader: boolean,
    Hotel: boolean,
    Age: number,
    Webzugang: string
}

export interface ContactMailingGroupObject {
    Contact_No: string,
    Mailing_Group_Code: string

}

export interface EventEntriesObject {
    No: string,
    Line_No: number,
    Participant_No: string,
    Type: string,
    Event_Description: string,
    Description_2: string,
    Start_Date: string,
    First_Name: string,
    Name: string,
    Status: string,
    Access_to_Online_Library: boolean,
    Remark: string,
    Bill_to_Code: string,
    Company_No: string,
    Bill_to_Customer_No: string,
    Bill_to_Contact_No: string,
    Bill_to_Name: string
}

export interface EventHotelObject {
    Event_No: string,
    Hotel_Code: string,
    Name: string,
    Hoteladresse: string,
    Hotelort: string,
    Hotelpostleitzahl: string,
    No_of_single_room: number,
    No_of_double_room: number,
    Price_single_room: number,
    Price_double_room: number,
    Price_double_as_single_room: number,
    Remark: string,
    Start_Reservation: string,
    End_Reservation: string,
    Reserved_SR: number,
    Reserved_DR: number,
    Blocked_SR: number,
    Blocked_DR: number,
    Tagungspauschale: number,
    Company: string
}

export interface EventLecturerObject {
    Event_No: string,
    Course_Leader_No: string,
    Name: string,
    Name_2: string,
    Remark: string,
    Direction: boolean,
    Company: string
}

export interface HotelReservationObject {
    Bemerkung: string, // Personal Note / Remarks
    Entry_No: number,
    Event_No: string,
    Contact_No: string, // Contact_No of the person who created the booking
    Contact_No_Participant: string,
    Hotel_Code: string,
    Number_of_SR: number,
    Number_of_DR: number,
    Hotel_Accom_from: string,
    Hotel_Accom_until: string,
    No_of_DR_as_SR: number,
    First_Name?: string, // first name of the person that was signed up
    Surname: string // surname of the person that was signed up
}

export interface EventServicesObject {
    Table_Name: string,
    Event_No: string,
    Service_Code: string,
    Price_Group_Code: string,
    Unit_of_Measure_Code: string,
    Service_Type: string,
    Price_Group_Description: string,
    Description: string,
    Quantity: number,
    Price_LCY_net: number,
    Amount_LCY_net: number,
    Early_Bird_Price: number,
    Web_Additional_participants: boolean,
    Company: string
}

export interface AdditionalEventsObjects {
    No: string,
    Event_No: string,
    Description: string,
    Description_2: string,
    No_of_participants: number,
    Price: number,
    Free_of_charge: boolean,
    Date: string,
    Start_time: string,
    Place_of_Event: string,
    Name_Place_of_Event: string,
    Reserved_participants: number,
    Room_No: string
}

export interface EventSettingsObject {
    lastPublished: string,
    publish: boolean,
    reminderDate: Date,
    logoValue?: string,
    lecturerPosition?: any,
    htmlCode?: string,
    html?: {
        title?: string,
        lecturer?: string,
        summary?: string,
        hotelcost?: string,
        program?: string,
        clause?: string,
        annotation?: string,
    },
    markdown?: {
        title?: string,
        lecturer?: string,
        summary?: string,
        hotelcost?: string,
        program?: string,
        clause?: string,
        annotation?: string,
    }
}

export interface UserLogin {
    company: string,
    firstName: string,
    lastName: string,
    mailingGroup: string,
    userLog: any
}

/**
 * Must set this data object for the test case to run according to the requirement
 * Story_No: string, - contains the number of test case
 * Admins: Array, - contains list of Admin records to be created
 *                  'subscribeEvent: boolean' - Whether to subscribe user to event or not.
 *                  'company?: string' - Optional if given than user will be created for specific company if not than for 'Test-Company'
 * Users: Array, - contains list of User records to be created
 *                  'subscribeEvent: boolean' - Whether to subscribe user to event or not.
 *                  'company?: string' - Optional if given than user will be created for specific company if not than for 'Test-Company'
 * Events: Array, - contains list of Events to be created
 *                              'eventType: string' - contains the type of event whether 'Close' or 'Open'.
 *                              'eventStatus: string' - contains the type of event whether 'Full' or 'Min'.
 *                              'eventSettingsAllowed: boolean' - contains boolean on whether to save eventSettings for this event or not.
 *                              'participantAllowed: number' - contains number of participants to be created for the event.
 *                              'Lecturers: number' - will contains the no of lecturers to be created with the event.
 *                              'Hotels: number' - will contains the no of hotels to be created with this event.
 *                              'deadlinePassed: boolean' - will contains boolean to decide whether event deadline will be passed or not.
 *                              'subscribeAdmin: boolean' - will contains boolean to decide whether event will allow a admin to subscribe with it or not.
 *                              'eventFinalized: boolean' - will contains boolean to decide whether event is finalized or not.
 * Event_Contact: boolean, - contains boolean on whether to create event Contact Person for all the events or not.
 */

export interface GenerateTestDataObject {
    deleteUnRegUser: string,
    Story_No: string,
    Admins: [{ subscribeEvent: boolean, company?: string }],
    Users: [{ subscribeEvent: boolean, company?: string }],
    Events: [
        {
            eventType: string,
            eventStatus: string,
            eventSettingsAllowed: boolean,
            participantAllowed: number,
            Lecturers: number,
            Hotels: number,
            deadlinePassed: boolean,
            subscribeAdmin: boolean,
            eventFinalized: boolean,
            eventEntryStatus?: string,
            paticipant?: string,
            applicant?: string,
            hasBooking?: boolean
            noErfaBooking?: boolean
            noNavisionEntry?: boolean
        }],
    Event_Contact: boolean
}

/**
 * Return date (YYYY-MM-DD)
 * @param addSubDays - Optional argument, +x will increment x days to current date, and -x will decrement -x from current date
 */
export function getSpecifiedDate(addSubDays?: number) {
    const currentDate = new Date();
    if (addSubDays) {
        currentDate.setDate(currentDate.getDate() + addSubDays);
    }
    let currMonth = (currentDate.getMonth() + 1).toString();
    let currDate = (currentDate.getDate()).toString();

    currMonth = currMonth.length === 2 ? currMonth : '0' + currMonth;
    currDate = currDate.length === 2 ? currDate : '0' + currDate;

    return currentDate.getFullYear() + '-' + currMonth + '-' + currDate;
}

/**
 * To generate a random name for the file from an already given string
 * @param length - contains the no of characters to be present in file name.
 */
export function generateRandomString(length: number) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
}

/**
 * Extracts the file extension from the given path & attaches it to already given filename
 * @param fileName - contains the name of file
 * @param actualFilePath - contains the full path of file received
 */
export function copyExtension(fileName: string, actualFilePath: string) {
    return fileName + '.' + actualFilePath.split('.').pop();
}

/**
 * Checks whether the file is an image or not on the basis of extension
 * @param fileName - contains the full file name with extension
 */
export function fn_IsImage(fileName: string) {
    // console.log('fn_IsImage: Check whether this file is an image or not fileName:', fileName);
    const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp'];
    const extension = fileName.toLowerCase().split('.').pop();
    return extension ? imageExtensions.includes(extension) : false;
}

/**
 * Checks whether the file is an official document or not
 * @param fileName - contains the full file name with extension
 */
export function fn_IsOfficeFile(fileName: string) {
    // console.log('fn_IsOfficeFile: Check whether this file is an official document:', fileName);
    const officialFileExtensions = ['pdf', 'doc', 'docx', 'ppt', 'xls', 'xlsx', 'pptx', 'xltx'];
    const extension = fileName.toLowerCase().split('.').pop();
    return extension ? officialFileExtensions.includes(extension) : false;
}
