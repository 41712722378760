import { Component } from '@angular/core';

@Component({
  selector: 'app-test-chunk-load-error',
  templateUrl: './test-chunk-load-error.component.html',
  styleUrls: ['./test-chunk-load-error.component.scss']
})
export class TestChunkLoadErrorComponent {


  generateChunkLoadError(){
    localStorage.setItem('chunkLoadError', 'false');
    throw new Error('ChunkLoadError: Loading chunk src_app_components_components_module_ts failed.');
  }

  generateError(){
    localStorage.setItem('chunkLoadError', 'false');
    throw new Error('Testing Simple Error');
  }
}
