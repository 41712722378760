import { initializeApp } from 'firebase/app';
import { getFunctions } from 'firebase/functions';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { getAuth } from 'firebase/auth';

export const environment = {
    production: true,
    useEmulators:false,
    name: 'inpraxi production',
    feedbackEmail: '',
    devBugsnagEnabled: false,
    region: 'europe-west2',
    backendUrl: 'https://europe-west2-inpraxi-produktiv.cloudfunctions.net',
    firebase: {
        apiKey: "AIzaSyDFvbgrNnCJZ8I_O-Ikihc_csy-82WEiz0",
        authDomain: "inpraxi-produktiv.firebaseapp.com",
        databaseURL: "https://inpraxi-produktiv.firebaseio.com",
        projectId: "inpraxi-produktiv",
        storageBucket: "inpraxi-produktiv.appspot.com",
        messagingSenderId: "637385555629",
        appId: "1:637385555629:web:bf7d03f06d97525ff75024",
        measurementId: "G-074VWDWBPR"
    }
};
export const app = initializeApp(environment.firebase);
export const functions = getFunctions(app, environment.region);
export const firestore = getFirestore(app);
export const storage = getStorage(app);
export const auth = getAuth(app);