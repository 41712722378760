import { AfterViewInit, Component } from '@angular/core';

import { RecaptchaVerifier } from "firebase/auth";
import {auth} from '../environments/environment'
import { ChunkErrorHandlerService } from './services/chunk-error-handler.service';
@Component({
    selector: 'inpraxi-npx-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit {
    title = 'inpraxi Erfa Online';

    constructor() {
    }

    ngAfterViewInit(): void {
        window['recaptchaVerifier'] = new RecaptchaVerifier( 'recaptcha-container',{}, auth);
    }
}
