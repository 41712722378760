import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { ContactDocPath, ContactMailingGroupCollectionPath, UserDoc } from '../../shared-lib/entities';
import { BehaviorSubject, Subject } from 'rxjs';
import { Router } from '@angular/router';
import { environment, auth, firestore } from '../../environments/environment';
import {
    onAuthStateChanged,
    verifyPasswordResetCode,
    confirmPasswordReset,
    sendPasswordResetEmail,
    signInWithEmailAndPassword,
    signOut
} from 'firebase/auth';
import { initializeApp, getApps } from 'firebase/app';
import {
    doc,
    collection,
    query,
    getDoc,
    where,
    getDocs
} from 'firebase/firestore';
import {fromPromise} from "rxjs/internal/observable/innerFrom";



@Injectable({
    providedIn: 'root'
})

export class AuthService {
    userClaims = new BehaviorSubject({});

    private user: UserDoc | any = null;
    userDetailsUnsubscribe = null;

    loginStatus = new Subject();

    authState: any = null;

    constructor(private router: Router) {

        if (!getApps().length) {
            initializeApp(environment.firebase);
        }

        onAuthStateChanged(auth, usr => {

            if (usr === null) {
                this.loginStatus.next(null);
                this.user = {
                    erfaGroups: [],
                    role: ''
                };
                return;
            }

            this.authState = usr;
            this.loginStatus.next(true);
        });
    }


    verifyPasswordResetCode(code: string) {
        auth.useDeviceLanguage();
        return fromPromise(verifyPasswordResetCode(auth, code));
    }

    confirmPasswordReset(code: string, newPassword: string) {
        auth.useDeviceLanguage();
        return fromPromise(confirmPasswordReset(auth, code, newPassword));
    }

    public login(credentials: { email: string, password: string }) {

        return fromPromise(signInWithEmailAndPassword(auth, credentials.email, credentials.password))
            .pipe(tap(r => ({ ...r.user })));
    }

    sendResetPasswordLink(email: string) {

        auth.useDeviceLanguage();
        return fromPromise(sendPasswordResetEmail(auth, email));
    }

    isLogin(): Promise<any> {

        return new Promise<any>((resolve, reject) => {
            onAuthStateChanged(auth, usr => {

                if (usr) {
                    auth.currentUser.getIdTokenResult().then(t => {
                        this.userClaims.next(t.claims);
                        resolve(true);
                    });
                } else {
                    reject(false);
                }
            });
        });
    }

    verifyRole(roles) {

        return new Promise<any>((resolve, reject) => {

            if (roles.includes(this.userClaims.getValue()['role'])) {
                resolve(true);
            } else {
                console.error('verifyRole: user rejected: role not matching');
                reject(false);
            }
        });
    }

    isLoggedInAndRole(roles) {

        return new Promise<any>((resolve, reject) => {

            this.isLogin().then(() => {

                    if (roles.includes(this.userClaims.getValue()['role'])) {
                        resolve(true);
                        return;
                    }
                    console.error('isLoggedInAndRole: user rejected: role not matching');
                    reject(false);
                    return;
                },
                (unauthenticated) => {
                    console.error('isLoggedInAndRole: user rejected: not logged in', unauthenticated);
                    reject(false);
                    return;
                });
        });
    }

    checkUserClaims() {

        if (this.userDetailsUnsubscribe !== null) {
            this.userDetailsUnsubscribe = null;
        }

        return this.userDetailsUnsubscribe = auth.currentUser.getIdTokenResult(true).then(token => {

            if (token.claims.role !== undefined) {
                this.loginStatus.next(token);
                this.userClaims.next(token.claims);
                return Promise.resolve(token.claims);
            } else {
                return Promise.reject(`User claims are undefined for user : ${auth.currentUser.uid}`);
            }
        }, error1 => {
            const msg = 'checkUserClaims: getIdTokenResult: ' + error1.toString();
            this.loginStatus.next(null);
            console.error(msg);
            return Promise.reject(msg);
        });
    }

    async loadUserDetails(userId) {
        this.user = {
            role: '',
            erfaGroups: []
        };

        let contact;

        try {

            const docRef = doc(firestore, ContactDocPath(userId));
            contact = await getDoc(docRef);
            if (contact.data().Webzugang === 'WEBZUGANG') {
                this.user.role = 'user';
            }
        } catch (e) {
            console.log('loadUserDetails: No ContactMailingGroups entry for uid', userId);
        }

        // attempt to find all ContactMailingGroup entries related to this user id.
        // This will only work for actual users. Some users might not have any entry there.
        let contactMailingGroups;

        try {
            const q = query(collection(firestore, ContactMailingGroupCollectionPath()), where('Contact_No', '==', userId));
            contactMailingGroups = await getDocs(q);
            // add every ContactMailingGroup to the users erfaGroups-Array
            contactMailingGroups.forEach(cmg => {
                console.log("cmg-------", cmg.data());
                this.user.erfaGroups.push(cmg.data().Mailing_Group_Code);
            });
        } catch (e) {
            // no contact data found. This might be a manually created admin user.
            console.log('loadUserDetails: No ContactMailingGroups entry for uid', userId);
        }

        // attempt to retrieve the role from custom claims
        try {
            const tokenResult = await auth.currentUser.getIdTokenResult();
            this.user.role = tokenResult.claims.role  || 'user';
        } catch (e) {
            // do not allow a login if the TokenIdResult fails.
            console.error('loadUserDetails: Failed to getIdTokenResult for uid', userId, e);
            this.user.role = '';
        }

        return this.user;
    }

    logout() {

        signOut(auth).then(() => {
            this.authState = null;
            this.loginStatus.next(null);
            this.router.navigate(['/auth/login']).then().then()
                .catch((err) => {
                    console.log('Error occurred while accessing Login page', err);
                });
        });
    }
}

