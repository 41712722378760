import { BrowserModule, HammerGestureConfig, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { ErrorHandler, LOCALE_ID, NgModule, Injectable } from '@angular/core';
import { initializeFirebase } from '../config/firebase.config';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './material/material.module';
import { FormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';
import { StorageModal } from './services/storageModal';
import Bugsnag from '@bugsnag/js'
import { BugsnagErrorHandler } from '@bugsnag/plugin-angular'
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireDatabaseModule } from '@angular/fire/compat/database';
import { environment } from '../environments/environment';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import { HttpClientModule } from '@angular/common/http';
import { NgxPrintModule } from 'ngx-print';
import 'hammer-timejs';

/*Emulators*/
import { USE_EMULATOR as USE_DATABASE_EMULATOR } from '@angular/fire/compat/database';
import { USE_EMULATOR as USE_FUNCTIONS_EMULATOR } from '@angular/fire/compat/functions';
import { USE_EMULATOR as USE_FIRESTORE_EMULATOR } from '@angular/fire/compat/firestore';
import { ChunkErrorHandlerService } from './services/chunk-error-handler.service';
import { TestChunkLoadErrorComponent } from './test-chunk-load-error/test-chunk-load-error.component';


export function errorHandlerFactory() {
  return new ChunkErrorHandlerService(new BugsnagErrorHandler());
}

Bugsnag.start({
  apiKey:  '44bd12baa2055ec88f918853e835e583',
  appVersion: '1.0.35p',
});

/*@Injectable()
export class MyHammerConfig extends HammerGestureConfig {
    overrides = <any>{
        // override hammer js default configuration
        'swipe': { direction: Hammer.DIRECTION_ALL }
    };
}*/

/*
* Initialize firebase App
* */
initializeFirebase();

// const bugsnagClient = bugsnag({
//     apiKey: '7340ea2e5d4a52da9ca877d291f4649d',
//     notifyReleaseStages: environment.devBugsnagEnabled ? ['production', 'staging', 'development'] : ['production', 'staging']
// });

registerLocaleData(localeDe);

// @ts-ignore
/*
if (window.Cypress) {
    environment.devBugsnagEnabled = true;
    console.log('Bugsnag has been enabled');
}
*/
// else {
//     console.log('Cypress is not running...');
// }

// console.log('is DevBugsnagEnable : ', environment.devBugsnagEnabled);

/*const bugsnagClient = Bugsnag.start({
    apiKey: '7340ea2e5d4a52da9ca877d291f4649d',
    enabledReleaseStages: environment.devBugsnagEnabled ? ['production', 'staging', 'development'] : ['production', 'staging']
});*/

// export function errorHandlerFactory() {
//     return new BugsnagErrorHandler(bugsnagClient);
// }

@NgModule({
  declarations: [
    AppComponent,
    TestChunkLoadErrorComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    HttpClientModule,
    FormsModule,
    ToastrModule.forRoot(),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireDatabaseModule,
    NgxPrintModule
  ],
  providers: [StorageModal,
    { provide: LOCALE_ID, useValue: 'de' },
    /*   {
           provide: ErrorHandler,
           useFactory: errorHandlerFactory
       },*/
    /*   {
           provide: HAMMER_GESTURE_CONFIG,
           useClass: MyHammerConfig
       },*/
    AngularFirestore,
    AngularFireStorage,
    ChunkErrorHandlerService,
    { provide: USE_DATABASE_EMULATOR, useValue: environment.useEmulators ? ['localhost', 9000] : undefined },
    { provide: USE_FIRESTORE_EMULATOR, useValue: environment.useEmulators ? ['localhost', 8080] : undefined },
    { provide: USE_FUNCTIONS_EMULATOR, useValue: environment.useEmulators ? ['localhost', 5001] : undefined },
    { provide: ErrorHandler, useFactory: errorHandlerFactory }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor() {
    // bugsnagClient.notify(new Error('Test error'))
  }

}
